'use strict'

{
  let menuButtons = document.getElementsByClassName('c-menu-button')
  Array.prototype.slice.call(menuButtons).forEach(item => {
    item.addEventListener('click', function(){
      let body = document.body
      body.classList.toggle('menu-is-open')
    })
  })
}
