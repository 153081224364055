class Scroll{
  constructor(className){
    this.items = document.getElementsByClassName(className)
    if(this.items.length > 0) this.init()
  }
  init(){
    function animate(item){
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let windowHeight = window.innerHeight
      let itemTop = item.getBoundingClientRect().top + scrollTop
      let itemHeight = item.clientHeight
      if(scrollTop + windowHeight > itemTop + 320 && item.animated === false){
        item.classList.remove('is-unactive')
      }
    }

    window.addEventListener('scroll', function(){
      Array.prototype.slice.call(this.items).forEach(item => {
        animate(item)
      })
    }.bind(this))

    Array.prototype.slice.call(this.items).forEach(item => {
      item.animated = false
      item.classList.add('is-unactive')  
      animate(item)
    })
  }
};
