class Header{
  constructor(){
    this.header = document.getElementById('header')
    if(this.header) this.init()
  }
  init(){
    window.addEventListener('scroll', function(){
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let windowHeight = window.innerHeight
      if(scrollTop > (windowHeight - 5)){
        this.header.classList.add('is-visible')
      }else{
        this.header.classList.remove('is-visible')
      }
    }.bind(this))    
  }
}