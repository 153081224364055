class AnchorLink{
  constructor(){
    this.buttons = document.getElementsByClassName('anchor-link')
    if(this.buttons.length > 0) this.init()
  }
  init(){
    Array.prototype.slice.call(this.buttons).forEach(item => {
      item.addEventListener('click', function(e){
        var speed = 500
        var href= $(this).attr("href")
        var target = $(href == "#" || href == "" ? 'html' : href)
        var position = target.offset().top
        $("html, body").animate({scrollTop:position}, speed, "swing")

        let body = document.body
        body.classList.remove('menu-is-open')

        e.preventDefault()
      })
    })
  }
}